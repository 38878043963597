import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { announcements } from "./constant";
import {
  deleteAnnouncementsApi,
  getAnnouncementsApi,
  postAnnouncementsApi,
  searchAccountantAssignApi,
  searchClientApi,
  updateAnnouncementsApi,
} from "./api";

function* getAnnouncementsFunction({ payload }) {
  try {
    yield put({
      type: announcements.GET_ANNOUNCEMENT_LOADING,
      payload: {},
    });
    const response = yield call(getAnnouncementsApi, { payload });
    if (response.data.status) {
      yield put({
        type: announcements.GET_ANNOUNCEMENT_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: announcements.GET_ANNOUNCEMENT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: announcements.GET_ANNOUNCEMENT_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* searchAccountantAssignFunction({ payload }) {
  try {
    yield put({
      type: announcements.SEARCH_ACCOUNTANT_LOADINGS,
      payload: {},
    });
    const response = yield call(searchAccountantAssignApi, { payload });
    if (response.data.status) {
      yield put({
        type: announcements.SEARCH_ACCOUNTANT_SUCCESSS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: announcements.SEARCH_ACCOUNTANT_ERRORS,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: announcements.SEARCH_ACCOUNTANT_ERRORS,
      payload: { message: error?.message },
    });
  }
}

function* searchClientFunction({ payload }) {
  try {
    yield put({
      type: announcements.SEARCH_CLIENT_LOADING,
      payload: {},
    });
    const response = yield call(searchClientApi, payload);
    if (response.data.status) {
      yield put({
        type: announcements.SEARCH_CLIENT_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: announcements.SEARCH_CLIENT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: announcements.SEARCH_CLIENT_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* addAnnouncementFunction({ payload }) {
  try {
    yield put({
      type: announcements.POST_ANNOUNCEMENT_LOADING,
      payload: {},
    });
    const response = yield call(postAnnouncementsApi, { payload });
    if (response.data.status) {
      yield put({
        type: announcements.POST_ANNOUNCEMENT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: announcements.POST_ANNOUNCEMENT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: announcements.POST_ANNOUNCEMENT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: announcements.POST_ANNOUNCEMENT_ERROR,
      payload: error?.data,
    });
    yield put({
      type: announcements.POST_ANNOUNCEMENT_RESET,
      payload: {},
    });
  }
}

function* updateAnnouncementFunction({ payload }) {
  try {
    yield put({
      type: announcements.UPDATE_ANNOUNCEMENT_LOADING,
      payload: {},
    });
    const response = yield call(updateAnnouncementsApi, { payload });
    if (response.data.status) {
      yield put({
        type: announcements.UPDATE_ANNOUNCEMENT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: announcements.UPDATE_ANNOUNCEMENT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: announcements.UPDATE_ANNOUNCEMENT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: announcements.UPDATE_ANNOUNCEMENT_ERROR,
      payload: error?.data,
    });
  }
}

function* deleteAnnouncementFunction(action) {
  try {
    yield put({
      type: announcements.DELETE_ANNOUNCEMENT_LOADING,
      payload: {},
    });
    const response = yield call(deleteAnnouncementsApi, action);
    if (response.data.status) {
      yield put({
        type: announcements.DELETE_ANNOUNCEMENT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: announcements.DELETE_ANNOUNCEMENT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: announcements.DELETE_ANNOUNCEMENT_ERROR,
        payload: { ...response.data },
      });
      yield put({
        type: announcements.DELETE_ANNOUNCEMENT_RESET,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: announcements.DELETE_ANNOUNCEMENT_ERROR,
      payload: error,
    });
    yield put({
      type: announcements.DELETE_ANNOUNCEMENT_RESET,
      payload: {},
    });
  }
}

export function* getAnnouncementsSaga(): any {
  yield takeEvery(announcements.GET_ANNOUNCEMENT, getAnnouncementsFunction);
}

export function* postAnnouncementsSaga(): any {
  yield takeEvery(announcements.POST_ANNOUNCEMENT, addAnnouncementFunction);
}

export function* searchAccountantAssignSaga(): any {
  yield takeEvery(announcements.SEARCH_ACCOUNTANTS, searchAccountantAssignFunction);
}
export function* searchClientSaga(): any {
  yield takeEvery(announcements.SEARCH_CLIENT, searchClientFunction);
}

export function* updateAnnouncementsSaga(): any {
  yield takeEvery(
    announcements.UPDATE_ANNOUNCEMENT,
    updateAnnouncementFunction
  );
}

export function* deleteAnnouncementsSaga(): any {
  yield takeEvery(
    announcements.DELETE_ANNOUNCEMENT,
    deleteAnnouncementFunction
  );
}

function* AnncouncementsSaga(): any {
  yield all([
    fork(getAnnouncementsSaga),
    fork(postAnnouncementsSaga),
    fork(updateAnnouncementsSaga),
    fork(deleteAnnouncementsSaga),
    fork(searchClientSaga),
    fork(searchAccountantAssignSaga),
  ]);
}

export default AnncouncementsSaga;
